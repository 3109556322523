.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  h1 {
    font-weight: 800;
  }
  h2 {
    font-weight: 700;
  }
  h3 {
    font-weight: 600;
  }
  h4 {
    font-weight: 500;
  }
  h5 {
    font-weight: 400;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }
}

/* styles.css */
.CollapsibleContent[data-state='open'] {
  animation: collapse-slide-down 250ms ease-in-out;
}
.CollapsibleContent[data-state='closed'] {
  animation: collapse-slide-up 250ms ease-in-out;
}

@keyframes collapse-slide-down {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
    opacity: 100;
  }
}

@keyframes collapse-slide-up {
  from {
    height: var(--radix-collapsible-content-height);
    opacity: 100;
  }
  to {
    height: 0;
    opacity: 0;
  }
}

.AccordionTrigger[data-state='open'] > svg {
  transform: rotate(180deg);
}

/* .AccordionTrigger[data-state='open'] {
  background: #000000;
  border-color: transparent;
}

.AccordionTrigger[data-state='open'] span {
  color: white;
}

.AccordionTrigger[data-state='open'] svg {
  fill: white;
} */

.AccordionContent[data-state='open'] {
  animation: AccordionSlideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionContent[data-state='closed'] {
  animation: AccordionSlideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

/* .AccordionContent[data-state='open'] > div button {
  opacity: 0;
}

.AccordionContent[data-state='open'] > div button {
  opacity: 1;
} */

@keyframes AccordionSlideDown {
  from {
    height: 0;
    /* opacity: 0; */
  }
  to {
    height: var(--radix-accordion-content-height);
    /* opacity: 1; */
  }
}

@keyframes AccordionSlideUp {
  from {
    height: var(--radix-accordion-content-height);
    /* opacity: 1; */
  }
  to {
    height: 0;
    /* opacity: 0; */
  }
}

.ProgressRoot {
  transform: translateZ(0);
}
.ProgressIndicator {
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}

.pulse {
  animation: opace 1.7s ease-in-out infinite;
}

@keyframes opace {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.5;
  }
}

.control {
  @apply inline-flex items-center gap-1 whitespace-nowrap rounded-[0.6rem] border border-[0.12rem] border-transparent bg-black/5 px-3 py-[0.32rem] text-start text-base outline-none transition duration-200 placeholder:text-black/30 placeholder:opacity-60 hover:opacity-80 focus:outline-none disabled:bg-neutral-100 dark:border-white/5 dark:bg-neutral-900 dark:placeholder:text-white/50 dark:focus:border-white dark:disabled:bg-white/15 dark:disabled:text-white/30 [&.error]:border-red-600;
}
